body {
  /* background-color: #E6E4AE; */
  background-color: #F3DFA6;
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 50vh;
  /* background-color: #10a37f; */
  /* background-color: #70706a; */

}

.form > input, button, .select {
  height: 40px;
  width: 60%;
  border-radius: 30px;
  text-align: center;
  border: 1px solid black;
}

.form > button {
  border-radius: 50px;
  font-family: monospace;
  cursor: pointer;
  font-size: x-large;
  color: black;
  background-color: transparent;
  border: 2px solid black;
  width: 15%;
}

.form > h1 {
  font-family: cursive, system-ui;
  color: #170B2E;
  font-size: xxx-large;
}

.description-input-box{
  font-size: large;
}

.select {
  font-family: monospace;
  font-weight: bold;
  font-size: x-large;
}

.form > button:hover {
  border: 2px solid black;
}


.btn-generate:hover {
  background-color:black;
  color: white;
}